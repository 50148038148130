<template>
  <form @submit.prevent="onSubmit" class="no-mb no-mt">
    <div class="row">
      <div class="col-xs-12">
        <div
          class="form-group"
          :class="{ 'has-error': $v.form.email.$anyError }"
        >
          <label class="form-label">Email</label>
          <input
            type="email"
            v-model.trim="$v.form.email.$model"
            class="form-control"
            :class="{ 'is-invalid': submitted && $v.form.email.$error }"
          />
          <span v-if="submitted && !$v.form.email.email" class="error"
            ><label class="error"
              >Debes introducir un email válido.</label
            ></span
          >
          <span v-if="submitted && !$v.form.email.required" class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>

        <div
          class="form-group"
          :class="{ 'has-error': $v.form.password.$anyError }"
        >
          <label class="form-label">Contraseña</label>
          <input
            type="password"
            v-model.trim="$v.form.password.$model"
            class="form-control"
            :class="{ 'is-invalid': submitted && $v.form.password.$error }"
          />
          <span v-if="submitted && !$v.form.password.required" class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>

        <div
          class="form-group"
          :class="{ 'has-error': $v.form.passwordConfirm.$anyError }"
        >
          <label class="form-label">Confirmar Contraseña</label>
          <input
            type="password"
            v-model.trim="$v.form.passwordConfirm.$model"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.form.passwordConfirm.$error,
            }"
          />
          <span
            v-if="submitted && !$v.form.passwordConfirm.sameAsPassword"
            class="error"
            ><label class="error">Las contraseñas no coinciden.</label></span
          >
          <span
            v-if="submitted && !$v.form.passwordConfirm.required"
            class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>

        <div
          class="form-group form-check" style="display: flex;"
          :class="{ 'has-error': $v.form.termsAccept.$anyError }"
        >
          <input
            type="checkbox"
            id="termsCheck"
            v-model="$v.form.termsAccept.$model"
            class="form-check-input"
            :class="{ 'is-invalid': submitted && $v.form.termsAccept.$error }"
          />
          <label class="form-check-label ml-5" for="termsCheck">Acepto los términos y condiciones</label>
          <span
            v-if="submitted && !$v.form.passwordConfirm.required"
            class="error"
            ><label class="error">Este campo es obligatorio.</label></span
          >
        </div>

        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary mt-10 btn-corner right-15"
          >
            Crear Cuenta
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "RegisterView",
  components: {},
  computed: {},
  data() {
    return {
      errors: [],
      submitted: false,
      form: {
        password: "",
        email: "",
        passwordConfirm: "",
        termsAccept: false,
      },
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
      termsAccept: { required },
      passwordConfirm: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    async onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

    const user = new this.$moralis.User()
    
    user.set("username", this.form.email);
    user.set("password", this.form.password);
    user.set("email", this.form.email);
    console.log(user)
    try {
        const user2 = await user.signUp();
        console.log(user2)
    } catch (error) {
    
        alert("Error: " + error.code + " " + error.message);
    }

    },
  },
};
</script>
